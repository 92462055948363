.section--whattoexpect {
	background-color: white;

	h2 {
		margin-bottom: 0.5em;
		color: rgb(14, 18, 19);
		font-size: 2.5rem;
	}

	@media screen and (min-width: 48rem) {

		padding-bottom: 8rem;

		.rhombus--e {
			width: 40%;
			height: 90%;
			top: 0%;
			right: 0%;
			background: rgba(78, 93, 108, 1);
			z-index: 0;
		}
	}

	@media screen and (min-width: 64rem) {
		padding-top: 2rem;

		h2 {
			font-size: 4rem;
		}
	}
}

.expectCards {
	position: relative;

	display: grid;
	grid-template-columns: 1fr;
	column-gap: 2rem;
	row-gap: 2rem;

	@media screen and (min-width: 48rem) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 64rem) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.expectCard {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	background-color: rgba(56, 67, 79, 1);
	border-radius: 0.625rem;
	box-shadow: 0px 22px 30px -10px rgba(0, 0, 0, 0.5);
	padding: 2rem;

	&__image {
		width: 25%;
		// padding: 1rem;
		max-width: 5rem;
		margin-right: 2rem;
		flex-shrink: 0;
	}

	&__label {
		flex-shrink: 1;
		font-size: 1.5rem;
		line-height: 1;
	}
}
