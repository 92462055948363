.section--testimonials {

	background: white;
	// background-image: url('../images/testimonials-photo@2x.jpg');
	// background-size: cover;

	.section__inner {
		padding: 6rem 0 2rem;
	}

	.superheadline {
		margin: 0 0 0.5em 0;
	}

	.img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.image {
		object-fit: cover;
		object-position: 25% 80%;
		opacity: 0.4;
	}

	.testimonials {
		position: relative;
		z-index: 2;
	}

	.testimonial {
		position: relative;
		padding: 3.5rem 3rem 2.5rem;
		background: rgba(25, 31, 36, 1);
		color: white;
		border-radius: 0.625rem;
		box-shadow: 0px 2px 40px -5px rgba(0, 0, 0, 0.5);

		&__inner {
			position: relative;
		}

		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			height: 15%;
			width: 15%;
			background-image: url('../images/quote.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			z-index: 5;
		}
		&::before {
			top: 5%;
			left: -7.5%;
		}

		&::after {
			bottom: 6%;
			right: -7.5%;
			transform: rotate(180deg);
		}

		&__headshot {
			position: absolute;
			height: 6rem;
			width: 6rem;
			top: -6.5rem;
			right: 50%;
			transform: translateX(50%);

			.img {
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				border-radius: 100%;
				overflow: hidden;
			}
		}
	}

	.testimonial + .testimonial {
		margin-top: 6rem;
	}


	cite {
		display: block;
		margin-top: 1rem;
		font-style: normal;
	}

	@media screen and (min-width: 64rem) {

		.section__inner {
			padding: 8rem 0;
		}

		.img {
			width: 70%;
			height: 80%;
			top: 10%;
			left: 20%;
			right: 10%;
		}

		.image {
			opacity: 1;
		}

		.testimonial {
			max-width: 32rem;
			padding-top: 2.5rem;

			&__headshot {
				height: 10rem;
				width: 10rem;
				top: 0rem;
				right: -20%;
				transform: none;
			}

			&__inner {
				// padding-right: 30%;
			}
		}

		.testimonial + .testimonial {
			margin-top: 2rem;
		}

		.rhombus--d {
			width: 40%;
			height: 90%;
			top: 5%;
			left: 30%;
			background: rgb(56, 67, 79);
			z-index: 0;
		}

	}
}

.flourish--e {
	display: none;
}

@media screen and (min-width: 64rem) {
	.flourish--e {
		display: block;
		width: 10vw;
		height: 5rem;
		left: 3rem;
		top: 1rem;
	}
}
