.section--getinvolved {
	.superheadline,
	.getinvolved__headline {
		text-align: center;
	}

	.getinvolved__headline {
		margin: 0.25em auto;
		font-size: 2rem;
		max-width: 50rem;
		line-height: 1;
	}

	.getinvolved__dots {
		display: block;
		width: 70%;
		margin: 3rem 0;
	}

	.getinvolved__limited {
		margin: 0 auto;
		max-width: 50rem;
		text-align: center;
		line-height: 1.25;
		font-size: 1.25rem;
		color: white;
	}

	.getinvolved__ctablocks {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 3rem;
		margin: 3rem 0 4rem;
	}

	.getinvolved__ctablock {
		position: relative;
		padding: 3rem 2rem 5rem 2rem;
		margin-bottom: 3rem;
		border-radius: 0.625rem;
		background-color: rgba(25, 31, 36, 1);

		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
			top: -1rem;
			left: 1rem;
			border-radius: 0.625rem;
			border: 1px solid rgba(10, 159, 228, 0.5);
			z-index: -1;
		}

		h3 {
			margin: 0 0 2em;
			text-transform: uppercase;
			font-size: 1.5rem;
			text-align: right;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			img {
				max-width: 5rem;
			}
		}
	}

	.requestCta {
		display: inline-block;
		padding: 0.5em 1em 0.35em 1em;
		margin: 1.5em 0 0;
		border: 1px solid rgba(10, 159, 228, 1);
		font-size: 1.25em;
		transition: all 100ms linear;

		&:hover {
			background: white;
			border-color: white;
			color: black;
		}
	}

	@media screen and (min-width: 48rem) {
		.getinvolved__ctablocks {
			grid-template-columns: 1fr 1fr;
		}

		.getinvolved__ctablock {
			margin-bottom: 0;
		}

		.getinvolved__limited {
			font-size: 1.5rem;
		}
	}

	@media screen and (min-width: 64rem) {
		.getinvolved__headline {
			font-size: 3rem;
		}

		.getinvolved__dots {
			width: 50%;
			margin: 5rem 0;
		}
	}

}
