.section--hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12rem 0 8rem;

    background-color: #101417;
    background: radial-gradient(100% 100% at 50% 50%, #101417 0%, #000000 100%);


    .section__content {
		position: relative;
        width: 50%;
        max-width: 40rem;
        min-width: 15rem;
		z-index: 1;
	}

	&__bg {
		position: absolute;
		z-index: 0;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;

		img {

			object-fit: cover;
		}
	}
}

.flourish--scroll {
	display: none;
}

@media screen and (min-width: 45rem) {
	.flourish--scroll {
		display: block;
		position: absolute;
		top: 50%;
		right: 1.5rem;
		transform: rotate(-90deg);

		font-family: "Teko";
		text-transform: uppercase;
		letter-spacing: 0.075em;

		span {
			display: inline-block;
			margin-left: 0.5em;
			border-top: 1px solid #2A95FF;
			width: 2rem;
			height: 0.25em;
		}
	}
}

.eventDetails {
	margin: 3rem auto 0;
	display: flex;

	justify-content: center;
	align-items: center;
	max-width: 30rem;

	font-size: 1.25em;
	text-transform: uppercase;

    &__date,
    &__online {
        display: flex;
		align-items: center;
		padding-top: 0.25rem;
		white-space: nowrap;
	}

	&__online {
		margin-left: 2rem;
	}

	&__icon {
		width: 1em;
		margin-right: 0.25em;
		padding-bottom: 0;
		flex-shrink: 0;
	}

	@media screen and (min-width: 45rem) {
		flex-direction: row;
		font-size: 2em;
	}

}
