.section--attendees {
	padding-top: 0rem;
	background: white;

	h2 {
		color: rgb(14, 18, 19);
		text-align: center;
		font-size: 2.5rem;
		margin-bottom: 0;
	}

	p.baseCopy {
		margin-bottom: 2em;
		margin-top: -1em;
	}

	@media screen and (min-width: 64rem) {
		padding-top: 2rem;

		h2 {
			font-size: 4rem;
		}
	}

	@media screen and (min-width: 75rem) {

	}
}

.rhombus--c {
	background: rgb(25, 31, 36);
	position: absolute;
	bottom: 10%;
	left: -15%;
	height: 6rem;
	width: 60%;

	@media screen and (min-width: 45rem) {
		bottom: 15%;
	}
}

.clubs {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 21rem;
	margin: 0 auto;
	position: relative;


	&__logo {
		width: 5rem;
		margin: 0 1rem 2rem;
		background: white;
		box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.5);
		border-radius: 0.625rem;
		overflow: hidden;
	}

	@media screen and (min-width: 45rem) {
		flex-wrap: nowrap;
		width: 35rem;
	}

	@media screen and (min-width: 75rem) {
		flex-wrap: nowrap;
		width: 60rem;

		&__logo {
			width: 8rem;
			margin: 0 1rem 1rem;
		}
	}

}
