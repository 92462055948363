.header {
    position: absolute;
    width: 100%;
    padding: 1.2rem 1.5rem 1.5rem;
    // height: 4rem;
    z-index: 10;

    background-color: transparent;
    transition: all 200ms ease-out;

    &--fixed {
        position: fixed;
        background-color: rgba(16,20,23,1);
        padding: 0.5rem 1.5rem;
        top: 0;
    }
}

.headerNav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &__wyscout,
    &__list {
        display: none;
    }

    &__wyscout {
        transform: translateY(0.125rem);
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        padding: 0.1em 0.5rem 0.125rem;

        line-height: 0;
        font-size: 1.5rem;

        white-space: nowrap;

        border-bottom: 2px solid transparent;

        transition: border 150ms ease-in-out;
    }

    .headerNav__item--getInvolved {
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid #2A95FF;
        background-color: rgba(16,20,23,0.4);

        &:hover {
            border-color: white;
        }
    }
}

@media screen and (min-width: 64rem) {
    .headerNav {

        justify-content: space-between;

        &__wyscout {
            display: flex;
            width: 8rem;

            svg {
                width: 100%;
            }
        }

        &__list {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            max-width: 45rem;
            padding: 0 2rem;

            li {
                /* display: flex; */
                /* justify-content: center; */
                /* align-items: center; */
            }
        }

        &__list &__item:hover {
            border-bottom: 2px solid #2A95FF;
        }
    }
}
