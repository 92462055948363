.section--about {

	.section__content {
		margin: 3rem 0;
	}

	.section__inner {
		max-width: 100rem;
		padding-bottom: 0;
	}

	.section__image {
		position: relative;
		margin: 0 1rem 1rem 0;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 124, 255, 0.6);

			clip-path: polygon(15% 100%, 100% 100%, 100% 15%);
			transform: translate(1rem, 1rem);
		}
	}

	h2 {
		margin: 0.25em 0 .75em;
		font-size: 2.5rem;
		line-height: 0.9;
	}

	p + p {
		margin-top: 1em;
	}

	@media screen and (min-width: 64rem) {

		overflow: visible;
		z-index: 1;

		.section__inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0;
		}

		.section__content {
			order: -1;
		}

		.section__image {
			width: 40%
		}

		.section__content {
			width: 50%;
			order: -1;
		}

		h2 {
			font-size: 3rem;
		}
	}

	@media screen and (min-width: 75rem) {
		h2 {
			font-size: 4rem;
		}
	}

}

.itemCards {

	margin: 0 auto 4rem;
	padding: 0;
	font-size: 1.5rem;
	max-width: 90rem;


	&__item {
		display: flex;
		align-items: center;
		padding: 1.25em 1.5em;
		margin-bottom: 1em;
		border-radius: 0.625rem;
		line-height: 1;
		background-color: rgb(78, 93, 108);
		box-shadow: 0px 2px 40px -5px rgba(0, 0, 0, 0.5);

		& > svg {
			height: 0.6em;
			width: 0.6em;
			margin-right: 0.5em;
			color: rgb(14, 18, 19);
		}

		& > span {
			padding-top: 0.1em;
		}
	}

	@media screen and (min-width: 64rem) {
		display: flex;
		justify-content: space-around;
		margin-bottom: 0;
		transform: translateY(50%);

		&__item {
			width: 30%;
			padding-left: 0;
			padding-right: 0;
			margin: 0;
			flex-grow: 0;
			justify-content: center;
		}
	}

	@media screen and (min-width: 75rem) {
		font-size: 2rem;

		&__item {
			flex-grow: 0;
		}
	}

}
