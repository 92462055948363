.section--banner {
    background:  #009CE2;

    .section__inner {
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .baseCopy--de {
        color: #fff;
    }

    .headline {
        margin: 0 0 0.5em;
        font-size: 2.5rem;
        line-height: 0.9;  
    }

    p + p {
        margin-top: 1em;
    }

    p > a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .button--getInvolved {
        display: inline-block;
        padding: 0.35em 2em;
        margin: 1.5em 0 0;
        border: 2px solid #fff;
        font-size: 1.25rem;
        transition: all 100ms linear;

        &:hover {
            background: #fff;
            border-color: #fff;
            color: #009CE2;
        }
    }

    .rhombus--f {
        height: 100vw;
        width: 60vw;
        top: 0;
        left: -10.5rem;
        background-color: rgba(25, 31, 36, 0.15);
        z-index: 0;
    }

    .flourish--f {
        display: none;
    }

    @media screen and (min-width: 640px) {
        .rhombus--f {
            left: -15.5rem;
        }
    }

    @media screen and (min-width: 900px) {
        .headline { font-size: 3rem; }

        .button--getInvolved { font-size: 1.625em; }

        .rhombus--f {
            left: -35.5rem;
        }

        .flourish--f {
            display: block;
            width: 10vw;
            height: 5rem;
            right: 0;
            top: 1rem;
        }
    }
}