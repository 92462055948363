/* Basic text styles */

.alpha {
	font-family: "Teko";
	font-weight: 600;
	line-height: 0.85;
}

.bravo,
.superheadline
{
	font-family: "Teko";
	font-weight: 300;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.superheadline {

	font-size: 1.25rem;
	line-height: 1;

	&--le {
		color: rgb(78, 93, 108);
	}
	&--de {
		color: rgb(78, 93, 108);
	}

	@media screen and (min-width: 40rem) {
		font-size: 1.5rem;
	}

}

.charlie {
	font-family: "Teko";
	font-weight: 400;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.baseCopy {

	font-family: Helvetica, sans;
	line-height: 1.5;
	font-size: 1rem;

	&--le {
		color: rgb(78, 93, 108);
	}

	&--de {
		color: rgb(190, 188, 188);
	}

	@media screen and (min-width: 75rem) {
		font-size: 1.25rem;
	}

}

.colon {
	font-weight: 400;
}

.text-white {
	color: white;
}

.text-center {
	text-align: center;
}
