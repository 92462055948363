.section--connect {
	.connect-image {
		display: none;
	}

	.section__content {
		position: relative;
		z-index: 2;
	}

	h2 {
		margin: 0.4em 0 0.6em;
		font-size: 2.5rem;
	}

	@media screen and (min-width: 64rem) {

		.section__inner {
			display: grid;
			grid-template-columns: auto 60%;
			column-gap: 2rem;
			align-items: center;
		}

		.connect-image {
			display: block;
			padding-bottom: 67%;
			grid-row-start: 1;
			grid-column-start: 2;
			grid-column-end: 2;
		}

		.section__content {
			grid-row-start: 1;
			grid-column-start: 1;
			grid-column-end: 1;

			padding: 2rem 0;
		}

		h2 {
			font-size: 3rem;
		}
	}

	@media screen and (min-width: 75rem) {
		.section__inner {
			grid-template-columns: auto 65%;
		}
	}

	@media screen and (min-width: 100rem) {
		.section__inner {
			grid-template-columns: auto 70%;
		}

		h2 {
			font-size: 4rem;
		}
	}
}
