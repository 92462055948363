.section--speakers {
	padding-top: 0rem;
	background: white;

	h2 {
		color: rgb(14, 18, 19);
		text-align: center;
		font-size: 2.5rem;
	}


	@media screen and (min-width: 64rem) {
		padding-top: 2rem;

		h2 {
			font-size: 4rem;
		}
	}
}

.speakers__comingsoon {
	padding: 2rem 0;
	margin: 0 auto;
	max-width: 30em;
}

.speakers {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	flex-wrap: wrap;
	margin: 0 auto;
}

.speaker {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1rem;
	// width: 13rem;

	&__headshot {
		padding: 3.5rem 2rem;
		width: 100%;
		max-width: 10rem;
		background-image: url('../images/speaker-action-angles.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		&__img {
			width: 100%;
			display: block;
			border-radius: 100rem;
			overflow:hidden;
			box-shadow: 0 0 20px rgba(0,0,0,0.3);
		}
	}

	&__superheadline {
		font-size: 1.1rem;
	}

	&__name {
		margin: 0.15em 0 0.35em;
		color: rgb(14, 18, 19);
		font-size: 2rem;
		line-height: 0.85;
	}

	&__bio {
		line-height: 1.25;
		font-size: 0.875rem;
	}

}

@media screen and (min-width: 48rem) {
	.speakers {
		flex-direction: row;
		// max-width: 40rem; // 3 cols
		max-width: 32rem;
	}

	.speaker {
		flex-direction: column;
		width: 13rem;
	}
}

@media screen and (min-width: 64rem) {

	.speakers__comingsoon {
		padding: 4rem 0;
	}

	.speakers {
		justify-content: center;
		// max-width: 60rem; // 3 cols
		max-width: 36rem; // 2 cols
	}

	.speaker {
		width: 15rem;
		margin-left: 1.5rem;
		margin-right: 1.5rem;

		&__headshot {
			max-width: 15rem;
		}

		&__superheadline {
			font-size: 1.25rem;
		}

		&__name {
			font-size: 2.5rem;
		}

		&__bio {
			font-size: 1rem;
		}
	}

	// .speaker + .speaker {
	// 	margin-left: 3rem;
	// }
}


.flourish--d {
	display: none;
}

@media screen and (min-width: 900px) {
	.flourish--d {
		display: block;
		width: 10vw;
		height: 5rem;
		right: 3rem;
		top: 1rem;
	}
}
