@import '_includes/preflight';

/* Box model */
/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html {
	font-size: 16px;
	scroll-behavior: smooth;
}

body {
	margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: #191919;
    color: white;
}

@import '_includes/typography';
@import '_includes/common';
@import '_includes/header';
@import '_includes/section_hero';
@import '_includes/section_about';
@import '_includes/section_attendees';
@import '_includes/section_testimonials';
@import '_includes/section_speakers';
@import '_includes/section_connect';
@import '_includes/section_whattoexpect';
@import '_includes/section_getinvolved';
@import '_includes/footer';
@import '_includes/section_banner';


.rhombus--a {
    top: 0;
    left: -3rem;
    height: 10rem;
    width: 5rem;
    background-color: rgba(25,31,36,0.5);
}

// .rhombus--b {
//     bottom: 0;
//     right: -2rem;
//     height: 5rem;
//     width: 40vw;
//     background-color: rgba(25,31,36,0.5);
// }

.flourish--a {
    width: 15rem;
    height: 5rem;
    left: 75%;
    bottom: 0;
}

.flourish--b {
    width: 7rem;
    height: 5rem;
    left: 3rem;
    bottom: 1rem;
}

.flourish--c {
    display: none;
}

@media screen and (min-width: 900px) {
    .rhombus--a {
        height: 30vw;
        width: 15vw;
        top: 0;
        left: -7.5rem;
    }

    .flourish--a {
        width: 22vw;
        height: 5rem;
        left: auto;
        right: 0;
        bottom: 0;
    }

    .flourish--b {
        width: 10vw;
        height: 5rem;
        left: 3rem;
        bottom: 1rem;
	}

	.flourish--c {
		display: block;
		width: 7rem;
		height: 5rem;
		right: 3rem;
		top: 1rem;
	}
}
