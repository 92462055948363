.section {
    position: relative;
    width: 100%;
	overflow: hidden;
	padding-left: 2rem;
	padding-right: 2rem;

	&__inner {
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	@media screen and (min-width: 40rem) {
		padding-left: 4rem;
		padding-right: 4rem;

		&__inner {
			padding-top: 6rem;
			padding-bottom: 6rem;
		}
	}
}

.rhombus,
.flourish {
    position: absolute;
    pointer-events: none;
}

.rhombus {
    /* background-color: green; */
    transform: skew(-30deg);
    /* border: 1px solid green; */
}


.img {
	position: relative;
	width: 100%;
	height: 0;

	& > img,
	& > svg {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		// Effectively hide alt text for unloaded images
		color: transparent;
	}
}
