.section--footer {

	padding-top: 2rem;
	padding-bottom: 2rem;

	.section__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0;
	}

	.footer__flourish {
		max-width: 5rem;
	}

	.eventDetails {
		margin: 1rem 0;
		font-size: 1.5rem;
	}

	.eventDetails__date {
		// margin-right: 2rem;
	}

	.footer__wyscout {
		display: block;
		padding-top: 0.5rem;
		max-width: 8rem;
		width: 100%;
		// color: rgba(10, 159, 228, 1);
		color: white;
	}

	@media screen and (min-width: 48rem) {
		.section__inner {
			flex-direction: row;
		}

		.eventDetails {
			margin: 0;
		}
	}

	@media screen and (min-width: 64rem) {
		.footer__wyscout {
			visibility: hidden;
		}
	}
}
